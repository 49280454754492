<!--*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
    <div class="vx-card p-6 vs-con-loading__container" id="div-loading">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->

        <vs-button
          class="mb-4 md:mb-0"
          v-can:permission="'news_create'"
          @click="goToNewNews()"
          >Add News</vs-button
        >
      </div>
      <br />

      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2">
                {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  itemsData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : itemsData.length
                }}
                From {{ itemsData.length }}
              </span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(40)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-4
          "
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        />
        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
          >Export CSV</vs-button
        >
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :enableRtl="$vs.rtl"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        @row-double-clicked="onRowDoubleClicked"
      ></ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

// Store Module
import moduleNewsManagement from "@/store/get-all-news/moduleNewsManagement.js";

// Cell Renderer
import CellRendererLink from "../hr-report/main-report/cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "../hr-report/main-report/cell-renderer/CellRendererStatus.vue";
// Router to navigate
import router from "@/router";
// import endpoint from "../../endpoints";
export default {
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererLink,
    CellRendererStatus
  },
  data() {
    return {
      page: 1,
      cities: [],
      areas: [],
      area_names: [],
      job_titles: [],
      company_categories: [{ category_id: "", company_id: "" }],
      news: { id: null, title: null },
      category: null,
      searchQuery: "",
      // AgGrid
      gridApi: null,
      gridOptions: {},
      columnApi: null,
      autoGroupColumnDef: null,
      rowData: null,
      defaultColDef: {
        resizable: true,
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true
      },
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          sortable: true,
          filter: true,
          width: 100
        },
        {
          headerName: "SUBJECT",
          field: "title",
          sortable: true,
          filter: true,
          width: 150
        },
        {
          headerName: "NEWS",
          field: "description",
          sortable: true,
          filter: true,
          width: 200
        },
        {
          headerName: "COMPANY",
          field: "company_categories",
          sortable: true,
          filter: true,
          width: 150,
          cellRenderer: function(params) {
            return params.data.company_categories
              .map(item => item.company_id)
              .join(",");
          }
        },
        {
          headerName: "CATEGORY",
          field: "company_categories",
          sortable: true,
          filter: true,
          width: 150,
          cellRenderer: function(params) {
            return params.data.company_categories
              .map(item => item.categories)
              .join(", ");
          }
        },
        {
          headerName: "DATE",
          field: "created_at",
          sortable: true,
          filter: true,
          width: 150
        },
        {
          headerName: "SOURCE",
          field: "image",
          sortable: true,
          filter: true,
          width: 200
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus
      }
    };
  },
  computed: {
    itemsData() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.news.items;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    fetchItemsData(val) {
      this.$store.dispatch("news/fetchItemsNews", val).catch(err => {
        this.$vs.notify({
          title: "Error",
          text: err,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    },
    goToNewNews() {
      router.push("/Add/News");
    },
    onRemoveSelected() {
      var selectedData = this.gridApi.getSelectedRows();
      //alert(JSON.stringify(selectedData));
      this.gridApi.applyTransaction({ remove: selectedData });

      //  printResult(res);
    },
    onRowDoubleClicked(event) {
      let permissions = this.$store.state.auth.permissions.length
        ? this.$store.state.auth.permissions
        : localStorage.getItem("permissions");
      if (permissions && permissions.indexOf("news_edit") === -1) {
        return;
      }
      router.push("/Update/News?SID=" + event.data.id);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    getAllNews() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store
        .dispatch("news/fetchItemsNews")
        .then(() => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
        })
        .catch(error => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }

    this.getAllNews();
  },
  created() {
    if (!moduleNewsManagement.isRegistered) {
      this.$store.registerModule("news", moduleNewsManagement);
      moduleNewsManagement.isRegistered = true;
    }
  }
};
</script>
